import { createAsyncThunk } from "@reduxjs/toolkit";

import { IError } from "@/models/inner-models";
import { EFilters } from "@/store/constants";
import instance from "@/store/middleware/api";

const tags = createAsyncThunk(EFilters.getFilters, async (_, thunkAPI) => {
  try {
    const resp = await instance.get("/tags", {});

    return resp.data;
  } catch (err) {
    console.log("error", err);
    const error = err as IError;
    return thunkAPI.rejectWithValue(error);
  }
});

export { tags };
