import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/material";

import { useIsDesktop } from "@hooks/layout";

import GradientDialog from "@components/Dialog";
import { HaveAnAccountTypography } from "@components/LoginDialog/components";
import { USER_SIGN_UP_ADVANTAGES } from "@components/UnauthorizedUserDialog/constants";
import uniqueId from "lodash/uniqueId";

import { Button, Link } from "@/ui-kit";
import { Text } from "@/ui-kit/typography";

import * as Styled from "./styles";

type TUnauthorizedUserSignUp = {
  open: boolean;
  handleClose: () => void;
  handleLogin: () => void;
  handleSignUp: () => void;
};

export const UnauthorizedUserDialog: FC<TUnauthorizedUserSignUp> = ({
  open,
  handleClose,
  handleLogin,
  handleSignUp,
}) => {
  const { t } = useTranslation("login");
  const { t: tUnauthorizedUser } = useTranslation("login", { keyPrefix: "unauthorized" });
  const isDesktop = useIsDesktop();

  return (
    <GradientDialog
      open={open}
      handleClose={handleClose}
      btn={
        <>
          <Stack spacing="24px" width="100%" marginTop={"30px"}>
            <Button isFullWidth isBigButton onClick={handleSignUp}>
              {tUnauthorizedUser("createAccount")}
            </Button>
            <HaveAnAccountTypography>
              <Trans
                t={t}
                i18nKey="haveAnAccount"
                components={{ linkToLogin: <Link component="button" onClick={handleLogin} size={"inherit"} /> }}
              />
            </HaveAnAccountTypography>
          </Stack>
        </>
      }
    >
      <Stack spacing="40px" alignItems="center" justifyContent="center" padding={"1px"}>
        <Text isHeading size={isDesktop ? 1.5 : 1.125}>
          {tUnauthorizedUser("title")}
        </Text>
        <Box display="flex" flexWrap="wrap" gap={isDesktop ? "16px" : "8px"}>
          {USER_SIGN_UP_ADVANTAGES().map(({ title, description, icon }) => (
            <Styled.AdvantageContainer key={uniqueId(`${title}_`)}>
              {icon}
              <Text
                isHeading
                isFullWidth
                size={isDesktop ? undefined : 0.875}
                marginTop="16px"
                textAlign="center"
                sx={{ "&&&": { fontFamily: isDesktop ? "Kaisei Opti" : "Raleway" } }}
              >
                {title}
              </Text>
              {isDesktop && (
                <Text isFullWidth size={0.875} textAlign="center" marginTop="8px">
                  {description}
                </Text>
              )}
            </Styled.AdvantageContainer>
          ))}
        </Box>
      </Stack>
    </GradientDialog>
  );
};
